<template>
  <v-app>
    
    <v-navigation-drawer
      app
      id="core-navigation-drawer"
      v-model="drawer"
      dark
      mobile-breakpoint="640"
      width="230"
      v-if="getIsLoggedIn"
    >
    
      <template v-slot:img="props">
        <v-img :gradient="`to bottom`" v-bind="props" />
      </template>
      <v-divider class="mb-1" />
      <v-list dense nav>
        <v-list-item>
          <v-list-item-avatar class="align-self-center" color="white" contain>
            <v-icon color="#000">mdi-account-convert</v-icon>
          </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title class="py-2 text-uppercase">{{ user.name == null ? user.user_ligin_id :
                            user.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider class="mb-2"></v-divider>
            <v-list expand nav dense :key="`list-${tenantId}`">
                <v-subheader>Menú</v-subheader>
                <v-list-item v-for='(m, x) in menu' :key="`menu-${x}`" link :to=m.url
                    v-if="!!idActionsRol.find(e => m.permissions.includes(e))">
                    <v-list-group no-action link v-if="m.config" class='menu-drop-down'>
                        <template v-slot:activator>
                            <v-icon class='mr-2'>{{ m.icon }}</v-icon> {{ m.name }}
                        </template>
                        <v-list-item dense sub-group class="pl-1" v-for="(dd, y) in m.dropDown" :key=y :to=dd.url
                            v-if="!!idActionsRol.find(e => dd.permissions.includes(e))">
                            <v-list-item-icon v-if="m.config" class='mr-2'>
                                <v-icon>{{ dd.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content v-if="m.config">
                                <v-list-item-title no-action>{{ dd.name }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item-icon v-if="!m.config" class='mr-2'>
                        <v-icon>{{ m.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content v-if="!m.config">
                        <v-list-item-title no-action>{{ m.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-main>
            <v-toolbar v-if=getIsLoggedIn dense>
                <v-app-bar-nav-icon @click="setDrawer(!drawer)"></v-app-bar-nav-icon>
                <v-toolbar-title> {{ this.titleToolbar }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu 
                    offset-y 
                    offset-x
                    v-if="notifications.length"
                    max-width="400"
                >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn  
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        depressed
                        plain
                        text
                        :ripple="false"
                        class="bell"
                      >
                        <v-icon color="success" class="shake">mdi-bell-ring-outline</v-icon>
                        <v-badge 
                          bottom
                          right
                          class="mr-6 " 
                          :content="notifications.length"  
                        />
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item-group color="primary">
                        <v-list-item
                          v-for="(n, i) in notifications"
                          :key="i"
                          @click="notificationAction(n)"
                        >
                          <v-list-item-content v-html="n.mensaje" />
                          <v-list-item-icon>
                            <v-icon color="success">mdi-window-restore</v-icon>
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                </v-menu>
                <v-icon>mdi-domain</v-icon> {{ company }}
                <v-menu v-model="dropdownMenu" :close-on-content-click="false" :nudge-width="200" offset-y
                    transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="blue-grey darken-1" dark v-bind="attrs" v-on="on" icon>
                            <v-icon>mdi-view-dashboard</v-icon>
                        </v-btn>
                    </template>
                    <v-card width="360px">
                        <v-list>
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-48px mdi-account-circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ user.name == null ? user.user_ligin_id :
                                        user.name }}</v-list-item-title>
                                    <v-list-item-subtitle>Permiso prinicipal</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon @click="logout">
                                        <v-icon>mdi-import</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-col cols="12">
                            <v-select v-model=tenantIdSelected :items="companies" item-text="name" item-value="tenantId"
                                label="Seleccione una empresa" outlined dense @change=setSelectedCompany></v-select>
                        </v-col>
                        <v-row class=' text-center' v-if=modules.length>
                            <v-col cols='6' md='4' v-if=pos @click="setDrawer(true)">
                                <v-btn height="60px" to="/posinicio">
                                    <v-img src="./assets/images/pos.png" width="50px">
                                    </v-img>
                                </v-btn>
                                <div class='title-icon-application mt-1'>P.O.S</div>
                            </v-col>
                            <!-- <v-col cols='6' md='4'>
                                <v-btn 
                                    link
                                    height="60px"
                                >
                                    <v-img 
                                        src="./assets/images/laboratorio.png"
                                        width="50px"
                                    >
                                    </v-img>
                                </v-btn>
                                <div class='title-icon-application mt-1'>LABORATORIO</div>
                            </v-col> -->
              <v-col
                cols="6"
                md="4"
                v-if="contabilidad"
                @click="setDrawer(true)"
              >
                <v-btn height="60px" to="/contabilidad">
                  <v-img src="./assets/images/nomina.png" width="50px"> </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">CONTABILIDAD</div>
              </v-col>
              <v-col cols="6" md="4" v-if="store" >
                <v-btn height="60px" to="/bodega">
                  <v-img src="./assets/images/bodega.png" width="50px"></v-img>
                </v-btn>
                <div class="title-icon-application mt-1">BODEGA</div>
              </v-col>
              <v-col cols="6" md="4" v-if="purchases" >
                <v-btn height="60px" to="/compras">
                  <v-img src="./assets/images/compras.png" width="50px">
                  </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">COMPRAS</div>
              </v-col>
              <v-col cols="6" md="4" v-if="admin" >
                <v-btn height="60px" to="/administracion">
                  <v-img src="./assets/images/administrador.png" width="50px">
                  </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">ADMINISTRACIÓN</div>
              </v-col>
              <v-col cols="6" md="4" v-if="adminWeb" >
                <v-btn height="60px" to="/administracion-web">
                  <v-img src="./assets/images/ecommerce.png" width="50px">
                  </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">ECOMMERCE</div>
              </v-col>

              <v-col cols="6" md="4" v-if="adminCrm" >
                <v-btn height="60px" to="/inicio-crm">
                  <v-img src="./assets/images/crm-1.png" width="50px"> </v-img>
                </v-btn>
                <div class="title-icon-application mt-1">CRM</div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="mx-2">
                <v-alert type="error" dense>
                  Sin aplicaciones asignadas
                </v-alert>
              </v-col>
            </v-row>
          </v-card>
        </v-menu>
      </v-toolbar>
      <router-view class="mt-2"></router-view>
    </v-main>
    <v-footer padless color="grey lighten-2">
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>{{ company }}</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    tenantIdSelected: null,
    dropdownMenu: false,
    ws:null
  }),
  computed: {

    company() {
      var c = this.companies.find((e) => e.tenantId == this.tenantId);
      return typeof c != "undefined" ? c.name : "Grupo Innofarm";
    },

    ...mapGetters("access", [
      "getIsLoggedIn",
      "pos",
      "contabilidad",
      "store",
      "purchases",
      "users",
      "admin",
      "adminWeb",
      "adminCrm",
    ]),

    ...mapState("access", ["modules", "idActionsRol"]),

    ...mapState("master", [
      "companies",
      "user",
      "urlWs",
      "token",
      "overlay",
      "tenantId",
      "drawer",
      "menu",
      "titleToolbar",
      "notifications"
    ]),

    drawer: {
      get: function() {
        return this.$store.state.master.drawer;
      },
      set: function() {
        return this.$store.state.master.drawer;
      },
    },
  },
  methods: {
  
    ...mapActions("master", ["requestApi", "alertNotification", "setDrawer"]),
    ...mapActions("access", ["errorRequest", "getModules"]),
    ...mapActions("pos", ["dataPrimaryPos"]),
    ...mapMutations("master", [
      "setTenantId",
      "setUrl",
      "setMenu",
      "setDrawer",
      "setNotifications"
    ]),
    ...mapMutations("access", [
      "setLogedIn",
      "setActionsRol",
      "setAvailableModules",
    ]),

    setSelectedCompany() {
        localStorage.setItem('tenantId', this.tenantIdSelected)
        this.setTenantId(this.tenantIdSelected)
        this.getModules()
    },

    logout() {
      this.setUrl("user-laboratorio");
      this.requestApi({
        method: "DELETE",
        data: {
          access_token: this.token,
          userLoginId: this.user.user_ligin_id,
          dataBase: this.tenantId,
          system: "gestion_grupo_innofarm",
          comment: "ha cerrado sesión en el sistema de gestion de innofarm",
        },
      })
        .then((res) => {
          localStorage.removeItem("token");
          localStorage.setItem("tenantId", null);
          localStorage.setItem("user", "");
          this.setMenu([]);
          this.setTenantId(null);
          this.setLogedIn(false);
          this.setActionsRol();
          this.alertNotification({
            param: { html: "Adios " + this.user.name },
          });
          if (!this.loggedIn) this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err);
          let response = err.response;
          this.errorRequest({
            data: {
              datos: response.data.errors,
              status: response.status,
            },
          });
        });
    },

    connectWebSocket() {
               
        this.ws = new WebSocket(`${this.urlWs}?t=n&ch=${this.user.channelsNws.join('|')}`)
        this.ws.onmessage = this.onMessage
        this.ws.onclose = this.onClose
        this.ws.onerror = this.onError

        this.ws.onopen = e => {

            console.log("Connection established!",e)

        }

    },

    onMessage(event) {
       
        let data = JSON.parse(event.data)

        if(data.tipo === "TRANSFERENCIA_ELECTRONICA"){

          this.notifications.push(data)

        }

    },

    onclose(close){

      console.log('ws onclose: '+close)

    },

    onerror(err){

        console.log('ws onerror: '+err)
    },

    notificationAction(n){

      if(n.url != '' && n.url != null)
        window.open(n.url,'_blank')

    },

  },
  beforeUpdate() {

    if(location.pathname.includes('cotizacion') || location.pathname.includes('punto_venta')){
 
      if(!this.ws && this.user.channelsNws.length)
        this.connectWebSocket()

    }else{

        this.setNotifications([])
  
        if(this.ws){

          this.ws.close()
          this.ws=null

        }

    }

    this.tenantIdSelected = this.tenantId;
    this.setMenu(JSON.parse(localStorage.getItem("menu")))

  },
};
</script>

<style>
.title-icon-application {
  font-size: 11px !important;
}

td,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 0.8rem !important;
}

td.custom-price {
  font-size: 1rem !important;
  font-weight: 600;
}

td {
  /* height: 25px !important; */
}

td.td-custom {
  background: #e2e2e2;
  width: 176px;
}

td.td-head-custom {
  background: #8f8f8f;
  color: #fff;
  font-size: 1rem !important;
}

.input-quotation {
  position: relative;
  bottom: 4px;
  border-bottom: 0;
}

input {
  font-size: 14px;
}

.v-text-field input {
  padding-left: 3px;
}

.v-select.v-input--dense .v-select__selection--comma {
  margin: 8px 4px 0px 0 !important;
}

.theme--light.v-select .v-select__selection--disabled,
.v-select__selection--comma,
.v-select:not(.v-text-field--single-line):not(.v-text-field--outlined)
  .v-select__slot
  > input {
  font-size: 13px !important;
  color: #333 !important;
}

.v-select:not(.v-text-field--single-line):not(.v-text-field--outlined)
  .v-select__slot
  > input,
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
  padding: 6px 0 0px 0px !important;
  font-size: 13px !important;
  color: #333 !important;
}

.v-list-group {
  width: 100%;
}

.v-list-group__header {
  padding: 0 !important;
}

.menu-drop-down {
  font-size: 12px;
  font-weight: 500;
}

.v-list-group__header__append-icon {
  position: absolute;
  right: 0;
}

.bell .shake{
    animation: shake .8s  infinite;
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(0deg);
  }
  20%,
  60% {
    transform: rotate(15deg);
  }
  40%,
  80% {
    transform: rotate(-15deg);

  }
}
</style>
