export default {
  namespaced: true,
  state: {
    wayToPays: [],
    prescribers: [],
    shippingMethods: [],
    ubications: [],
    plazosPago: [],
    productStore: [],
    reasonsCancel: [],
    ubication: "",
    partyIdClient: "",
    quoteId: "",
    menu: [
      {
        name: "Inicio",
        url: "/posinicio",
        icon: "mdi-home",
        permissions: ["HOME"],
      },
      {
        name: "Punto de venta",
        config: true,
        icon: "mdi-desktop-classic",
        permissions: ["POS_COTIZADOR"],
        dropDown: [
          {
            name: "Pos",
            url: "/punto_venta",
            icon: "mdi-desktop-classic",
            permissions: ["POS_COTIZADOR"],
          },
          {
            name: "Rutas de entrega",
            url: "/rutas",
            icon: "mdi-routes",
            permissions: ["POS_COTIZADOR"],
          },
          /*{
                        name:'Rutas entregadas',
                        url: '/rutas',
                        icon:'mdi-check-all',
                        permissions: ["POS_COTIZADOR"]
                    },*/
        ],
      },
      {
        name: "Caja Actual",
        url: "/transacciones_caja",
        icon: "mdi-cash-register",
        permissions: ["POS_CAJA"],
      },
      {
        name: "Cajas (historico)",
        url: "/cajas_usuario",
        icon: "mdi-cash-multiple",
        permissions: ["POS_CAJA_HIST"],
      },
      {
        name: "Clientes",
        url: "/clientes",
        icon: "mdi-cash-multiple",
        permissions: ["POS_CAJA_HIST"],
      },
      {
        config: true,
        name: "Administracion",
        icon: "mdi-account-group",
        permissions: ["POS_ADMIN", "POS_ADMIN_CAJAS", "POS_ADMIN_CCOBRAR"],
        dropDown: [
          {
            name: "Cajas",
            url: "/lista_cajas",
            icon: "mdi-format-list-checks",
            permissions: ["POS_ADMIN_CAJAS"],
          },
          {
            name: "Facturacion",
            url: "/lista_facturas",
            icon: "mdi-format-list-checks",
            permissions: ["POS_ADMIN_CCOBRAR"],
          },
          {
            name: "Cuentas por cobrar",
            url: "/cuentas_por_cobrar",
            icon: "mdi-format-list-checks",
            permissions: ["POS_ADMIN_CCOBRAR"],
          },
          {
            name: "Prescriptores",
            url: "/prescriptores",
            icon: "mdi-format-list-checks",
            permissions: ["POS_ADMIN_PRESCRIPTOR"],
          },
        ],
      },
    ],
  },
  mutations: {
    setWayToPays(state, payload) {
      state.wayToPays = payload;
    },

    setPrescribers(state, payload) {
      state.prescribers = payload;
    },

    setShippingMethods(state, payload) {
      state.shippingMethods = payload;
    },

    setUbications(state, payload) {
      state.ubications = payload;
    },

    setUbication(state, payload) {
      state.ubication =
        typeof payload.product_store_id != "undefined"
          ? payload.product_store_id
          : payload;
    },

    setPartyIdClient(state, payload) {
      state.partyIdClient = payload;
    },

    setPlazosPago(state, payload) {
      state.plazosPago = payload;
    },

    setProductStore(state, payload) {
      state.productStore = payload;
    },

    setQuoteId(state, payload) {
      state.quoteId = payload;
    },

    setReasonCancel(state, payload) {
      state.reasonsCancel = payload;
    },


  },
  actions: {
    dataPrimaryPos({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        commit("master/setUrl", "rel-data", { root: true });
        dispatch(
          "master/requestApi",
          {
            method: "POST",
            data: {
              formasPago: true,
              prescriptores: true,
              direcciones: true,
              plazosPago: true,
              productStore: true,
              motivosCancelacion: true,
            },
          },
          { root: true }
        ).then((res) => {
          commit("setPrescribers", res.data.prescriptores);
          commit("setWayToPays", res.data.formasPago);
          commit("setUbications", res.data.direcciones);
          commit("setPlazosPago", res.data.plazosPago);
          commit("setProductStore", res.data.productStores);
          commit("setReasonCancel", res.data.motivosCancelacion);
          resolve();
        });
      });
    },

    dataShippmentMethods({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        commit("master/setUrl", "rel-data", { root: true });
        dispatch(
          "master/requestApi",
          {
            method: "POST",
            data: {
              metodosEnvio: true,
              productStoreId: state.ubication,
            },
          },
          { root: true }
        ).then((res) => {
          commit("setShippingMethods", res.data.metodosEnvio);
          console.log(res);
          resolve();
        });
      });
    },

    dataPrinter({ commit, state, dispatch }, payload) {
      //QUITO CITIMED
      let printTicket = "";
      let ipPrinter = "";
      let portPrinter = "";
      let labelPrinter = "";
      let labelIpPrinter = "";
      let printer = "";

      if (payload.product_store_id == "10000") {
        if (payload.shipment_method_type_id == "SHIPPING") {
          printTicket = payload.printers.find(
            (e) => e.rol == "IMPRESORA_TRASERA"
          );
        } else {
          printTicket = payload.printers.find(
            (e) => e.rol == "IMPRESORA_DELANTERA"
          );
        }

        if (typeof printTicket != "undefined") {
          printer = printTicket.printer;
          ipPrinter = printTicket.ip;
          portPrinter = printTicket.printer_port;
        }

        let labelP = payload.printers.find(
          (e) => e.rol == "IMPRESORA_TICKET_TRASERA"
        );

        if (typeof labelP != "undefined") {
          labelPrinter = labelP.printer;
          labelIpPrinter = labelP.ip;
        }
      }

      //GUAYAQUIL (WTC)
      if (payload.product_store_id == "10010") {
        if (payload.shipment_method_type_id == "SHIPPING") {
          printTicket = payload.printers.find(
            (e) => e.rol == "IMPRESORA_TRASERA"
          );
        } else {
          printTicket = payload.printers.find(
            (e) => e.rol == "IMPRESORA_DELANTERA"
          );
        }

        if (typeof printTicket != "undefined") {
          printer = printTicket.printer;
          ipPrinter = printTicket.ip;
          portPrinter = printTicket.printer_port;
        }

        let labelP = payload.printers.find(
          (e) => e.rol == "IMPRESORA_TICKET_TRASERA"
        );

        if (typeof labelP != "undefined") {
          labelPrinter = labelP.printer;
          labelIpPrinter = labelP.ip;
        }
      }

      return {
        printTicket,
        ipPrinter,
        portPrinter,
        labelPrinter,
        labelIpPrinter,
        printer,
      };
    },

    notificationsQuotes({ commit, state, dispatch }){

      commit("master/setUrl", "user-laboratorio/0", { root: true });

      dispatch(
        "master/requestApi",
        {
          method: "GET",
          data: {},
        },
        { root: true }
      ).then((res) => { 
        
        commit("master/setNotifications", res.data.notificaciones.map(e => JSON.parse(e.cuerpo)), { root: true })

      });

    }
  }
}
